import React, { Fragment } from "react";
import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import ConfrencingRoom from "./components/ConfrencingRoom";
import { v4 } from "uuid";
import { Button, Checkbox, Container, Grid, TextField } from "@material-ui/core";
function App() {
  const [details, setRoomName] = useState({ roomName: "", name: "", profilePhoto: "" });

  return (
    <Fragment>
      {details.roomName ? (
        <ConfrencingRoom
          room_name={details.roomName}
          user_id={JSON.stringify(details)}
          onClose={() => {
            setRoomName({ name: "", roomName: "", profilePhoto: "" });
          }}
        />
      ) : (
        <RoomNameEnterer onJoin={setRoomName} />
      )}
    </Fragment>
  );
}

function RoomNameEnterer({ onJoin }: any) {
  const [userDetails, setUserDetails] = useState({ name: "", profilePhoto: "", roomName: "", is_admin: false });
  return (
    <Container style={{ marginTop: 30 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} container justify="center">
          <TextField
            value={userDetails.roomName}
            label="Enter Room Name"
            color="secondary"
            style={{ width: "300px" }}
            onChange={(e) => {
              setUserDetails((d) => ({ ...d, roomName: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12} container justify="center">
          <TextField
            value={userDetails.name}
            label="Enter Your Name"
            color="secondary"
            style={{ width: "300px" }}
            onChange={(e) => {
              setUserDetails((d) => ({ ...d, name: e.target.value }));
            }}
          />
        </Grid>
        <Grid item xs={12} container justify="center">
          <TextField
            value={userDetails.profilePhoto}
            label="Enter Profile Photo Url"
            color="secondary"
            style={{ width: "300px" }}
            onChange={(e) => {
              setUserDetails((d) => ({ ...d, profilePhoto: e.target.value }));
            }}
          />
        </Grid>
        <Grid item container xs={12} justify="center">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
            isAdmin{" "}
            <Checkbox
              checked={userDetails.is_admin}
              onChange={(e) => setUserDetails((d) => ({ ...d, is_admin: e.target.checked }))}
              style={{ marginLeft: 10 }}
            />
          </div>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button
            disabled={userDetails.name === "" || userDetails.profilePhoto === "" || userDetails.roomName === ""}
            style={{ width: "300px" }}
            onClick={() => {
              onJoin(userDetails);
            }}
            variant="contained"
            color="secondary"
          >
            Join Room
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
