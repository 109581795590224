import React, { Fragment, useEffect } from "react";
import { useConferenceRoom, useLocalStreams } from "../hooks/videoConfrencing";
import { Button, CircularProgress, Container, Grid, IconButton, Typography } from "@material-ui/core";
import Styles from "./styles.module.css";
import { Call, Mic, MicOff, ScreenShare, Videocam, VideocamOff } from "@material-ui/icons";
import { blue, red } from "@material-ui/core/colors";
import Video from "./Video";
import { getRTMIDBYRTC } from "./../utils/getRTMIDBYRTC";
import { mute_audio, unmute_audio } from "./../constants/AdminActions";

interface Props {
  room_name: string;
  user_id: string;
  onClose: any;
}

export default function ConfrencingRoom({ user_id, room_name, onClose }: Props) {
  const ls = useLocalStreams({ user_id });
  const { isChannelJoined, error, users, onPin, agoraClient } = useConferenceRoom({
    channel_name: room_name,
    user_id,
    my_stream: ls.user_stream,
  });
  const onVideoButtonClick = () => {
    if (ls.Video === "video" || ls.Video === "screen") {
      agoraClient.unpublish(ls.user_stream?.video);
    }
    ls.setUserVideo(ls.Video === "video" ? "none" : "video");
  };
  const onScreenShareButtonClick = () => {
    if (ls.Video === "video" || ls.Video === "screen") {
      agoraClient.unpublish(ls.user_stream?.video);
    }
    ls.setUserVideo(ls.Video === "screen" ? "none" : "screen");
  };
  const onAudioButtonClick = () => {
    if (ls.Audio) {
      agoraClient.unpublish(ls.user_stream?.audio);
    }
    ls.setUserAudio(!ls.Audio);
  };

  useEffect(() => {
    return () => {
      ls.user_stream?.audio?.close();
      ls.user_stream?.video?.close();
    };
  }, []);

  return (
    <Container style={{ maxWidth: 1500, marginTop: 20 }}>
      <Grid container spacing={1} style={{ transition: "1s all" }}>
        {JSON.parse(user_id).is_admin ? (
          <Grid item xs={12} container justify="space-around">
            <Button
              onClick={() => {
                users.forEach((user) => {
                  if (!user.is_me) {
                    ls.sendMessage(mute_audio, getRTMIDBYRTC(user.userId));
                  }
                });
              }}
              color="secondary"
              variant="contained"
            >
              Mute Everyone
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                users.forEach((user) => {
                  if (!user.is_me) {
                    ls.sendMessage(unmute_audio, getRTMIDBYRTC(user.userId));
                  }
                });
              }}
            >
              Unmute Everyone
            </Button>
          </Grid>
        ) : (
          ""
        )}
        {error || !isChannelJoined ? (
          <Grid item xs={12} container justify="center">
            {error ? (
              <Typography variant="h5">Some Error Occured Joining The Room..</Typography>
            ) : (
              <CircularProgress style={{ color: "black", marginTop: 100 }} />
            )}
          </Grid>
        ) : (
          <Fragment>
            {users.map((user: any) => {
              return (
                <Video
                  spaceOccupied={getGridHeight(users.length)}
                  Is_My_Video_On={ls.Video !== "none"}
                  onPin={onPin}
                  {...user}
                  key={user.userId}
                />
              );
            })}
          </Fragment>
        )}

        <BottomControlBar
          isAudioMuteByAdmin={ls.isAudioMuteByAdmin}
          isVideoMuteByAdmin={ls.isVideoMuteByAdmin}
          onScreenShareButtonClick={onScreenShareButtonClick}
          onVideoButtonClick={onVideoButtonClick}
          ls={ls}
          onClose={onClose}
          onAudioButtonClick={onAudioButtonClick}
        />
      </Grid>
    </Container>
  );
}

const getGridHeight = (len: any): 4 | 6 | 12 => {
  if (12 / len === 6) {
    return 6;
  }
  if (12 / len === 4) {
    return 6;
  }
  if (12 / len >= 12) {
    return 12;
  }
  return 6;
};

const BottomControlBar = ({
  onClose,
  onVideoButtonClick,
  ls,
  onAudioButtonClick,
  onScreenShareButtonClick,
  isAudioMuteByAdmin,
  isVideoMuteByAdmin,
}: any) => {
  return (
    <Fragment>
      <div style={{ width: "100%", height: 80 }}></div>
      <div className={Styles.MeetingControls_Container}>
        <Grid container style={{ width: "100%", height: "80px" }}>
          <Grid item xs={12} container justify="center" alignItems="center" style={{ height: "100%" }}>
            <CustomIconButton
              background={ls.Video === "video" ? "white" : isVideoMuteByAdmin ? "black" : red[600]}
              color={ls.Video === "video" ? "black" : "white"}
              onClick={onVideoButtonClick}
            >
              {ls.Video !== "video" ? <VideocamOff /> : <Videocam />}
            </CustomIconButton>

            <CustomIconButton color="red" onClick={onClose}>
              <Call />
            </CustomIconButton>

            <CustomIconButton
              background={ls.Audio ? "white" : isAudioMuteByAdmin ? "black" : red[600]}
              color={ls.Audio ? "black" : "white"}
              onClick={onAudioButtonClick}
              id={"audio_button_room"}
            >
              {ls.Audio ? <Mic /> : <MicOff />}
            </CustomIconButton>
            <CustomIconButton
              background={ls.Video === "screen" ? blue[800] : "white"}
              color={ls.Video !== "screen" ? "black" : "white"}
              onClick={onScreenShareButtonClick}
            >
              <ScreenShare />
            </CustomIconButton>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

const CustomIconButton = ({
  color = "black",
  background = "white",
  children,
  ...props
}: {
  color?: string;
  background?: string;
  children: any;
  [key: string]: any;
}) => {
  return (
    <IconButton
      style={{
        transition: "0.25s all",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        background,
        color,
        marginLeft: "3%",
        marginRight: "3%",
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};
