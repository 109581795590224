import React, { useEffect, useState } from "react";
import AgoraRTC, { IAgoraRTCRemoteUser, ILocalAudioTrack, ILocalVideoTrack } from "agora-rtc-sdk-ng";
import AgoraRTM, { RtmClient } from "agora-rtm-sdk";
import { agora_key } from "./../constants/agora_key";
import { getDetailsFromRTMID, getRTMIDBYRTC } from "./../utils/getRTMIDBYRTC";
import { mute_audio, unmute_audio } from "./../constants/AdminActions";

type VideoType = "screen" | "video" | "none";

interface useLocaStreamProps {
  user_id: string;
  audio?: boolean;
  video?: VideoType;
}

export const useLocalStreams = ({ user_id, audio = false, video = "none" }: useLocaStreamProps) => {
  const [Media, setMedia] = useState<getUserMediaReturnType | undefined>(undefined);
  const [agoraRtm] = useState<RtmClient>(AgoraRTM.createInstance(agora_key));
  const [Audio, setAudio] = useState(audio);
  const [Video, setVideo] = useState(video);
  const [isAudioMuteByAdmin, setIsAudioMuteByAdmin] = useState(false);
  const [isVideoMuteByAdmin, setIsVideoMuteByAdmin] = useState(false);
  useEffect(() => {
    agoraRtm
      .login({ uid: getRTMIDBYRTC(user_id) })
      .then(() => {
        console.log("Successfully logged in");
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  async function setUserAudio(set: boolean) {
    if (!isAudioMuteByAdmin) {
      Media?.audio?.close();
      const { audio } = await getUserAudio(set);
      setMedia((media) => {
        if (audio) {
          audio.on("track-ended", () => {
            setAudio(false);
          });
        }
        setAudio(set);
        return { ...media, audio };
      });
    } else {
      // show popup can't unmute
    }
  }
  async function setUserVideo(vt: VideoType) {
    if (!isVideoMuteByAdmin) {
      await Media?.video?.close();
      const { video } = await getUserVideo(vt);
      setMedia((media) => {
        if (video) {
          video.play(user_id);
          video.on("track-ended", () => {
            setVideo("none");
          });
        }
        setVideo(vt);
        return { ...media, video };
      });
    } else {
      //show popup that can't unmute the video
    }
  }
  useEffect(() => {
    agoraRtm.removeAllListeners("MessageFromPeer");
    agoraRtm.on("MessageFromPeer", (message: any, id) => {
      const [_, is_admin] = getDetailsFromRTMID(id);
      if (is_admin) {
        switch (message.text) {
          case mute_audio: {
            setIsAudioMuteByAdmin((state) => {
              if (Audio) {
                document.getElementById("audio_button_room")?.click();
              }
              return true;
            });
            break;
          }
          case unmute_audio: {
            setIsAudioMuteByAdmin(() => {
              return false;
            });
          }
        }
      }
    });
  }, [agoraRtm, Audio, Video]);
  async function sendMessage(message: string, uid: string) {
    agoraRtm
      .sendMessageToPeer({ text: message }, uid)
      .then((status) => {
        if (status.hasPeerReceived) {
          console.log("peer has successfully recieved message");
        } else {
          // will retry sending message until someone recieved it...!!
          setTimeout(() => {
            sendMessage(message, uid);
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  return {
    setUserAudio,
    setUserVideo,
    Audio,
    Video,
    user_stream: Media,
    agoraRtm,
    sendMessage,
    isAudioMuteByAdmin,
    isVideoMuteByAdmin,
  };
};

interface RoomUser {
  is_me: boolean;
  userId: string;
  user_stream?: IAgoraRTCRemoteUser | { audio: any; video: any };
}
export const useConferenceRoom = ({ channel_name, user_id, my_stream }: any) => {
  const [agoraClient, setAgoraClient] = useState(AgoraRTC.createClient({ codec: "vp8", mode: "rtc" }));
  const [users, setUsers] = useState<RoomUser[]>([{ is_me: true, userId: user_id, user_stream: my_stream }]);
  const [isChannelJoined, setIsChannelJoined] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (my_stream?.video) {
      agoraClient.publish(my_stream.video);
    }
    if (my_stream?.audio) {
      agoraClient.publish(my_stream.audio);
    }
  }, [my_stream]);
  useEffect(() => {
    agoraClient.on("user-joined", (user) => {
      setUsers((users) => [...users, { user_stream: user, is_me: false, userId: user.uid.toString() }]);
    });
    agoraClient.on("user-left", (left_user) => {
      setUsers((users) => users.filter((user) => user.userId !== left_user.uid));
    });
    agoraClient.on("user-published", async (user, mediaType) => {
      console.log("user published a stream bro..... gotta checkout   ....");
      console.log(user);
      await agoraClient.subscribe(user, mediaType);
      user.audioTrack?.play();
      user.videoTrack?.play(user.uid.toString());
    });
    agoraClient.on("user-unpublished", async () => {
      console.log("user unpublished");
    });
    agoraClient
      .join(agora_key, channel_name, null, user_id)
      .then(() => {
        setIsChannelJoined(true);
      })
      .catch((e) => {
        setError(true);
      });
    return () => {
      agoraClient.leave();
    };
  }, []);
  const onPin = (uid: string) => {
    setUsers((users) => {
      const pinned_user = users.find((user) => {
        return user.userId === uid;
      });
      if (pinned_user) {
        return [pinned_user, ...users.filter((user) => user.userId !== uid)];
      }
      return users;
    });
  };
  return { error, isChannelJoined, users, onPin, agoraClient };
};

interface getUserMediaReturnType {
  audio?: ILocalAudioTrack;
  video?: ILocalVideoTrack;
}

export default function videoConfrencing() {}

async function getUserVideo(type: VideoType) {
  if (type === "none") {
    return { video: undefined };
  } else if (type === "screen") {
    return { video: await AgoraRTC.createScreenVideoTrack({}, "disable") };
  } else {
    return { video: await AgoraRTC.createCameraVideoTrack() };
  }
}
async function getUserAudio(give: boolean) {
  if (give) {
    const audioStream = await AgoraRTC.createMicrophoneAudioTrack();
    return { audio: audioStream };
  } else {
    return { audio: undefined };
  }
}
