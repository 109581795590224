import { Avatar, Grid, IconButton, Typography } from "@material-ui/core";
import { NoMeetingRoom, Room } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Styles from "./styles.module.css";
interface Props {
  userId: string;
  onPin: (uid: string) => void;
  user_stream: any;
  spaceOccupied: any;
  is_me?: boolean;
  Is_My_Video_On?: boolean;
}

function Video(props: Props) {
  const { userId } = props;
  const [isPinned, setIsPinned] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {}, []);
  return (
    <Grid item xs={isPinned ? 12 : props.spaceOccupied} style={{ transition: "0.25s all" }}>
      <div className={Styles.aspectRatio16_9_Container}>
        <div className={Styles.aspectRatio16_9_child} id={userId} />
        <DetailsOverlay
          isVideoOn={!props.is_me ? props.user_stream?.hasVideo : props.Is_My_Video_On}
          profilePhoto={JSON.parse(userId).profilePhoto}
        />
        <PinOverlay
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          onPin={props.onPin}
          userId={userId}
          setIsPinned={setIsPinned}
        />
      </div>
    </Grid>
  );
}

const DetailsOverlay = ({ isVideoOn, profilePhoto }: any) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transition: "0.25s all",
        background: "rgba(0,0,0,0.5)",
        opacity: !isVideoOn ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
      }}
    >
      <Avatar style={{ width: "17%", height: "30%" }} src={profilePhoto} />
    </div>
  );
};

const PinOverlay = ({ isHovered, setIsHovered, onPin, setIsPinned, userId }: any) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        transition: "0.25s all",
        background: "rgba(0,0,0,0.5)",
        opacity: isHovered ? 1 : 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <IconButton
        style={{ color: "white", background: "rgba(0,0,0,0)", width: "15%", height: "30%" }}
        onClick={() => {
          onPin(userId);
          setIsPinned((p: any) => !p);
        }}
      >
        <Room />
      </IconButton>
    </div>
  );
};

export default Video;
